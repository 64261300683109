<template>
  <div class="totals">
    <SfProperty
      :name="$tc('{count} items worth', itemsQuantity)"
      :value="prices.subtotal | price"
      class="sf-property--full-width property property--total-items"
      :class="{'sf-property--large': isLarge}"
    />
    <SfProperty
      :name="$t('Order weight')"
      :value="totalWeight"
      class="sf-property--full-width property property--weight"
      :class="{'sf-property--large': isLarge}"
    />
    <MDiscountTotals :discounts="discounts" :is-large="isLarge" />
    <SfProperty
        v-if="isNewPost"
        :name="$t('Shipping')"
        :value="$t('Delivery Plan')"
        class="sf-property--full-width property"
        :class="{'sf-property--large': isLarge}"
    />
    <SfProperty
      v-else-if="prices.shipping || prices.shipping === 0"
      :name="$t('Shipping')"
      :value="prices.shipping | price"
      class="sf-property--full-width property"
      :class="{'sf-property--large': isLarge}"
    />

    <SfProperty
      v-if="tipsValue"
      :name="$t('Tips to the collector')"
      :value="tipsValue | price"
      class="sf-property--full-width property"
      :class="{'sf-property--large': isLarge}"
    />

    <SfProperty
      v-if="prices.collecting"
      :name="$t('Collecting')"
      :value="prices.collecting | price"
      class="sf-property--full-width property property--total-items"
      :class="{'sf-property--large': isLarge}"

    >
      <template #name="{ name }">
        <span class="sf-property__name">
          {{ name }}
          <AFloatingTooltip class="aft--full-width">
            <span class="totals__icon"></span>

            <template #tooltip>
              {{ $t('Collecting Tooltip') }}
            </template>
          </AFloatingTooltip>
        </span>
      </template>
    </SfProperty>

    <SfDivider class="divider" />

    <SfProperty
      :name="$t('Total')"
      :value="grandTotalValue | price"
      class="sf-property--full-width property property--grand-total"
      :class="{'sf-property--large': isLarge}"
    />
  </div>
</template>

<script>
import {
  SfProperty,
  SfDivider
} from '@storefront-ui/vue';
import MDiscountTotals from 'theme/components/molecules/m-discount-totals.vue';
import AFloatingTooltip from 'theme/components/atoms/a-floating-tooltip.vue';

export default {
  name: 'OTotals',
  components: {
    AFloatingTooltip,
    SfProperty,
    SfDivider,
    MDiscountTotals
  },
  props: {
    isLarge: {
      type: Boolean,
      default: false
    },
    summary: {
      type: Object,
      default: () => ({})
    },
    tips: {
      type: String,
      default: () => (0)
    },
    isNewPost: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    discounts () {
      return this.summary.discounts || []
    },
    itemsQuantity () {
      return this.summary.nonGiftItemsQuantityWithQTY
    },
    prices () {
      return this.summary.prices
    },
    totalWeight () {
      return this.summary.totalWeight
    },
    tipsValue () {
      return this.tips || 0
    },
    grandTotalValue () {
      return this.prices.grand_total + this.tipsValue
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import '~theme/css/fonts';

.totals {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 0 10px;
  box-sizing: border-box;

  &__icon {
    color: var(--orange);
    cursor: pointer;
    @include font-icon(var(--icon-info-outline));
  }

  @media only screen and (min-width: $tablet-min) {
    flex: 0 1 50%;
  }

  ::v-deep .property {
    margin: 0 0 var(--spacer-10);
    --property-name-content: none;
    --property-name-font-size: var(--font-base);
    --property-value-font-size: var(--font-base);
    --property-name-color: var(--black);
    --property-value-color: var(--black);
    --property-name-font-weight: var(--font-normal);
    --property-value-font-weight: var(--font-normal);
    --property-name-font-line-height: 1.2;

    &--total-items {
      margin: 0 0 var(--spacer-5);
    }

    &--weight {
      margin: 0 0 var(--spacer-15);
      --property-name-font-size: var(--font-sm);
      --property-value-font-size: var(--font-sm);
      --property-name-color: var(--dark-gray);
      --property-value-color: var(--dark-gray);
    }

    &--grand-total {
      margin: 0 0 var(--spacer-15);
      --property-name-font-size: var(--font-size-20);
      --property-value-font-size: var(--font-size-20);
      --property-name-font-weight: var(--font-medium);
      --property-value-font-weight: var(--font-medium);
    }

    @include for-desktop {
      &__total {
        padding: var(--spacer-base) 0 0 0;
      }
    }
  }
}

.divider {
  --divider-border-color: var(--c-white);
  --divider-width: 100%;
  --divider-margin: 0 0 var(--spacer-5);
  --divider-border-width: 0;
}
</style>
